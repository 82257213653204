import React, { Component } from 'react';

function IconAvatar() {

    return (
      <svg
        width='17'
        height='17'
        viewBox='0 0 17 17'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M8.31355 10.362H8.33672C8.36962 10.3622 11.5972 10.3668 13.4246 12.1849C13.8142 12.5726 14.1167 13.0234 14.3238 13.5249C14.5632 14.1045 14.6845 14.7734 14.6845 15.5131C14.6845 16.0582 15.1263 16.5 15.6713 16.5C16.2164 16.5 16.6582 16.0582 16.6582 15.5131C16.6582 14.5109 16.4856 13.5862 16.1453 12.7647C15.8363 12.0189 15.3862 11.3501 14.8074 10.7765C13.8569 9.83466 12.6776 9.26407 11.5859 8.91852C11.6575 8.85094 11.7276 8.78111 11.796 8.70874C12.7033 7.74521 13.203 6.46857 13.203 5.11373C13.203 3.75889 12.7033 2.48225 11.7958 1.51887C11.3493 1.04488 10.8279 0.672111 10.246 0.410893C9.63863 0.13831 8.99358 0 8.3285 0C7.66342 0 7.01837 0.13831 6.411 0.410893C5.82905 0.672111 5.30766 1.04502 4.86118 1.51887C3.95372 2.48225 3.45401 3.75889 3.45401 5.11373C3.45401 6.46857 3.95372 7.74521 4.86118 8.70859C4.92877 8.78036 4.9983 8.84944 5.06917 8.91643C3.97854 9.26108 2.80074 9.83047 1.85126 10.7704C1.2723 11.3435 0.822084 12.012 0.513018 12.7577C0.172551 13.5789 0 14.5034 0 15.5057C0 16.0507 0.441844 16.4925 0.98686 16.4925C1.53188 16.4925 1.97372 16.0507 1.97372 15.5057C1.97372 14.7661 2.09498 14.0973 2.33437 13.518C2.54131 13.017 2.8438 12.5666 3.23331 12.1793C5.04196 10.3816 8.21875 10.362 8.31355 10.362ZM8.3285 1.97372C9.92796 1.97372 11.2293 3.38239 11.2293 5.11373C11.2293 6.84507 9.92796 8.25374 8.3285 8.25374C6.72904 8.25374 5.42773 6.84507 5.42773 5.11373C5.42773 3.38239 6.72904 1.97372 8.3285 1.97372Z'
          fill='#eeebe8'
        />
      </svg>
    );
}

export default IconAvatar;