// import {withScope,captureException,captureMessage} from "@sentry/nextjs";
import { serialize } from "../helpers/serializeurl";
import { isServer } from "../helpers/common";

const HTTP_METHODS = [
  "get",
  "post",
  "head",
  "put",
  "patch",
  "delete",
  "options",
];

const customHeaders = (locale, extraheaders, referer, host, method) => {
  let contentHeader = {};
  if (method !== "post") {
    contentHeader = { "Content-Type": "application/json" };
  }
  let Authorization = process.env.FETCHAUTH;
  if ("Authorization" in contentHeader) {
    Authorization = contentHeader.Authorization;
    delete contentHeader.Authorization;
  }
  return {
    ...contentHeader,
    // locale: locale.replace("-", "_"),
    // Authorization,
    // Referer: referer,
    // Host: host,
    // ...extraheaders,
  };
};

const getURL = (url, params = null, skipEncode) => {
  if (params === null) {
    return url;
  } else {
    return `${url}?${serialize(params, skipEncode)}`;
  }
};

const base = (
  method,
  {
    url,
    locale,
    referer,
    host,
    data = {},
    extraheaders = {},
    additionalInfoForSentry = {},
    JSONdata = "",
    urlWithoutSlug = "",
    skipEncode = [],
  }
) => {
  function status(response) {
    if (response.status >= 200 && response.status < 300) {
      return Promise.resolve(response);
    } else {
      let errorDetailsObject = {
        page: referer,
        error: response,
        featureName: urlWithoutSlug !== "" ? urlWithoutSlug : url,
        url,
        extraheaders,
        additionalInfoForSentry,
      };
      // sendErrorToSentry(errorDetailsObject);
      return Promise.reject(new Error(response.status));
    }
  }

  let formData = new URLSearchParams();
  if (!isServer) {
    formData = new FormData();
  }
  for (const key of Object.keys(data)) {
    formData.append(key, data[key]);
  }

  if (JSONdata !== "") {
    formData = JSONdata;
  }

  let options = {
    method,
    headers: customHeaders(locale, extraheaders, referer, host, method),
    body: formData,
  };

  url = encodeURI(url);
  if (method === "get") {
    delete options.body;
    if (Object.keys(data).length !== 0 && data.constructor === Object) {
      url = getURL(url, data, skipEncode);
    }
  }

  return fetch(url, options)
    .then(status)
    .then(async (response) => {
      5;
      let responseHeaders = {};
      if (response.headers.get("nx-at")) {
        responseHeaders["nx-at"] = response.headers.get("nx-at");
      }
      if (response.headers.get("nx-at-expires")) {
        responseHeaders["nx-at-expires"] =
          response.headers.get("nx-at-expires");
      }

      return {
        ...(await response.json()),
        responseHeaders,
        status: response.status,
      };
    })
    .catch((error) => {
      return error;
    });
};

// const sendErrorToSentry = (errorDetailsObject) => {
//   let { error } = errorDetailsObject;
//   if (error && error !== null) {
//     withScope((scope) => {
//       if (errorDetailsObject) {
//         scope.setExtra('apiError', JSON.stringify(errorDetailsObject));
//       }
//       scope.setLevel('error');
//       scope.setExtra('errorType', 'API');
//       scope.setExtra('Page', errorDetailsObject.page);
//       scope.setTag('Category', errorDetailsObject.featureName);
//       captureMessage(
//         `API Error ${errorDetailsObject.featureName}`
//       );
//       captureException(error);
//     });
//   }
// };

const SuperFetch = {};

HTTP_METHODS.forEach((method) => {
  SuperFetch[method] = base.bind(null, method);
});

export default SuperFetch;
