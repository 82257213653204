/** @format */

import React, { useState } from "react";
import block from "bem-css-modules";
import IconAvatar from "../Icons/Avatar";

import Navigation from "../Navigation";
import dynamic from "next/dynamic";

import hamburger from "../../styles/components/Hamburger.module.scss";
import headerHamburger from "../../styles/components/Headerhamburger.module.scss";
const Overlay = dynamic(() => import("./../../components/Overlay"));
import { t } from "../../helpers/common";

const isServer = !(
  typeof window !== "undefined" &&
  window.document &&
  window.document.createElement
);

function Hamburger(props) {
  // const { t } = useTranslation();
  const [condition, setCondition] = useState(false);
  const [isMenu, setIsMenu] = useState(false);
  const {
    subscriptions,
    subscriptionInfo,
    userDetails,
    signupBook,
    authKeys,
    noBackground,
    searchIcon,
    openSuggest,
    openState,
    isHub,
  } = props;
  async function handleClick() {
    if(!isServer) {
      setIsMenu(true)
    }
  }

  const coloseOverlay = ()=>{
    if(!isServer) {
      setIsMenu(false)
    }
  }

  let outBannerMenu = {};
  let hamburgerOpen = {};
  let first = {};
  let second = {};
  let third = {};
  if (condition) {
    hamburgerOpen[`open`] = true;
    outBannerMenu[`${noBackground}`] = false;
    first[`first`] = true;
    second[`second`] = true;
    third[`third`] = true;
  } else {
    outBannerMenu[`${noBackground}`] = true;
    hamburgerOpen[`open`] = false;
    first[`first`] = false;
    second[`second`] = false;
    third[`third`] = false;
  }

  block.setSettings({
    modifierDelimiter: "--",
  });
  const headermenu = block(headerHamburger);
  const hamburgerMenu = block(hamburger);

  let outBanner = {};
  outBanner[`${noBackground}`] = true;

  const isLoggedIn = userDetails.isLoggedIn;
  const isAuthenticated = authKeys.isAuthenticated;

  let loginText = t("lang_header:account_Navigation.login.text");
  let userType = "logoutUser";
  if (!isAuthenticated) {
    userType = "logoutUser";
  } else if (isAuthenticated && !isLoggedIn) {
    userType = "visitor";
    loginText = t("lang_header:account_Navigation.logout.text");
  } else if (isAuthenticated && isLoggedIn) {
    userType = "loggedUser";
  }


  const firstname = isLoggedIn
    ? userDetails.userInfo.firstname === "" ||
      userDetails.userInfo.firstname === null
      ? t("lang_myPages:changePassword.heading")
      : userDetails.userInfo.firstname
    : "";
  return (
    <React.Fragment>
      <Overlay
        isOverlay={isMenu}
        overlayValue={"NAVIGATION"}
        coloseOverlay={coloseOverlay}
      />

      <div
        className={hamburgerMenu("", { ...hamburgerOpen })}
        onClick={() => handleClick()}
        id="cp_lnk_ham"
      >
        <span
          className={hamburgerMenu("bar", { ...outBannerMenu, ...first })}
        ></span>
        <span
          className={hamburgerMenu("bar", { ...outBannerMenu, ...second })}
        ></span>
        <span
          className={hamburgerMenu("bar", { ...outBannerMenu, ...third })}
          id={"hamburger_close_button"}
        ></span>
      </div>

      <div className={hamburgerMenu("navigation", { ...hamburgerOpen })}>
        <div className={headermenu("navigation")}>
          <Navigation
            handleClick={() => handleClick()}
            authKeys={authKeys}
            subscriptions={subscriptions}
            subscriptionInfo={subscriptionInfo}
            userDetails={userDetails}
            signupBook={signupBook}
          />
        </div>
      </div>
    </React.Fragment>
  );
}

export default Hamburger;
