export function serialize(obj, skipEncode) {
    var str = [];
    for (var p in obj)
        if (obj.hasOwnProperty(p)) {
            if(skipEncode.includes(p)){
                str.push(encodeURIComponent(p) + "=" + obj[p]);
            }else{
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
            
        }
    return str.join("&");
}