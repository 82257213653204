function UrlConfig({ hostname, locale, slug = null }) {
  const landingPage = `${hostname}/landing-page`;
  const investorsAbout = `${hostname}/investors-about-us`;
  const investorsSustainability = `${hostname}/investors-sustainability`;
  const investorsManagement = `${hostname}/investors-management`;
  const investorsDirectors = `${hostname}/investors-directors`;
  const investorsArticles = `${hostname}/investors-article`;
  const investorsMeetings = `${hostname}/investors-general-meetings`;
  const investorsContact = `${hostname}/contact`;
  const investorsRelease = `https://publish.ne.cision.com/papi/Release/${slug}/Detail?format=json`;
  const investorsPressReleases = `https://publish.ne.cision.com/papi/NewsFeed/${process.env.CISION_FEEDS_KEY_EN}?format=json&pageSize=5&Regulatory=false`;
  const investorsPressReleasesHome = `https://publish.ne.cision.com/papi/NewsFeed/${process.env.CISION_FEEDS_KEY_EN}?format=json&pageSize=3&Regulatory=false`;
  const investorsPressReleasesCheck = `https://publish.ne.cision.com/papi/NewsFeed/${process.env.CISION_FEEDS_KEY_EN}?format=json`;
  const investorsDisclaimer = `${hostname}/investors-disclaimer`;
  const investorsIpo = `${hostname}/investors-ipo`;
  const investorsReports = `${hostname}/investors-reports`;
  const investorsCalendar = `${hostname}/investors-finance-calendar`;
  const investorsShare = `${hostname}/investors-the-share`;

  return {
    fetchposts: `${landingPage}`,
    fetchInvestorsAbout: `${investorsAbout}`,
    fetchSustainability: `${investorsSustainability}`,
    fetchManagement: `${investorsManagement}`,
    fetchDirectors: `${investorsDirectors}`,
    fetchArticle: `${investorsArticles}`,
    fetchMeetings: `${investorsMeetings}`,
    fetchContact: `${investorsContact}`,
    fetchRelease: `${investorsRelease}`,
    fetchPressReleases: `${investorsPressReleases}`,
    fetchPressReleasesHome: `${investorsPressReleasesHome}`,
    fetchPressReleasesCheck: `${investorsPressReleasesCheck}`,
    fetchDisclaimer: `${investorsDisclaimer}`,
    fetchIpo: `${investorsIpo}`,
    fetchReports: `${investorsReports}`,
    fetchCalendar: `${investorsCalendar}`,
    fetchShare: `${investorsShare}`,
  };
}

export default UrlConfig;
