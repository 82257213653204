import Cookies from "js-cookie";
import { getEventId, isServer } from './common';
import SuperFetch from './../Api/superFetch';
import UrlConfig from './../Api/UrlConfig';

export const GTMPageView = () => {
    if (!isServer) {
        const event_id = getEventId();
        let originalLocation = `${window.location.protocol}//${window.location.hostname
            }${window.location.pathname}${window.location.search}`;
        var referrer = document.referrer;
        if (
            referrer.includes("www.nextory") ||
            referrer.includes("apptest.nextory.se")
        ) {
            if (sessionStorage.getItem('originalLocation')) {
                originalLocation = sessionStorage.getItem('originalLocation');
            } else {
                sessionStorage.setItem('originalLocation', originalLocation);
            }
        } else {
            sessionStorage.setItem('originalLocation', originalLocation);
        }
        const pageEvent = {
            event: "virtualPageview",
            virtualUrl: window.location.pathname,
            originalLocation: originalLocation,
            eventID: event_id,
        };
        window && window.dataLayer && window.dataLayer.push(pageEvent);
        setTimeout(() => {
            const pageEvent = {
                event: "virtualPageviewThirdParty",
                virtualUrl: window.location.pathname,
                originalLocation: originalLocation,
                eventID: event_id,
            };
            window && window.dataLayer && window.dataLayer.push(pageEvent);
        }, 3000);
        return pageEvent;
    }

};

export const GTMCTAClicked = async (t, locale, signupBook, authKeys, csrApiOrigin, host, referer) => {
    let urlConfig = UrlConfig({ hostname: csrApiOrigin });
    let bookDetails = {};
    if (signupBook && typeof signupBook.book !== 'undefined') {
        bookDetails = {
            bookId: signupBook.book.id,
            bookTitle: signupBook.book.title,
            authorName: signupBook.book.authorName,
            ebookISBN: signupBook.book.ebookISBN,
            audiobookISBN: signupBook.book.audiobookISBN
        };
    }
    const event_id = getEventId();
    let currency = t("lang_common:currency");
    if (currency === "kr") currency = "SEK";
    if (currency === "€") currency = "EUR";
    const pageEvent = {
        event: 'CTAClicked',
        currency,
        ...bookDetails,
        eventID: event_id,
        data: {
            ...bookDetails
        }
    };
    window && window.dataLayer && window.dataLayer.push(pageEvent);

    let fbData = {
        nx_event_id: event_id,
        nx_event_name: "InitiateCheckout"
    };
    if (Cookies.get("_fbc")) fbData["fbc"] = Cookies.get("_fbc");
    if (Cookies.get("_fbp")) fbData["fbp"] = Cookies.get("_fbp");
    if (currency) fbData["nx_currency"] = currency;
    if (bookDetails.bookId) fbData["bookids"] = [bookDetails.bookId];

    let extraHeaders = {};
    if (typeof authKeys.authkey !== "undefined") {
        extraHeaders = {
            "nx-at": authKeys.authkey
        };
    }
    await SuperFetch.post({
        url: urlConfig.facebook,
        locale, referer, host,
        JSONdata: JSON.stringify(fbData),
        extraheaders: {
            "Content-Type": "application/json",
            ...extraHeaders
        }
    });
    return pageEvent;
};

const getAuthorNames = authors => {
    let authorNamesList = [];
    authors &&
        authors.forEach(author => {
            authorNamesList.push(author.name);
        });
    return authorNamesList;
};

const getBookIsbn = (booktypes, requestedType) => {
    let isbn;
    booktypes.forEach(bookType => {
        if (bookType.type === requestedType) {
            return (isbn = bookType.isbn);
        }
    });
    return isbn;
};

const getBookproductType = (booktypes) => {
    let productType = "";
    var firstobj = true;
    booktypes.forEach(bookType => {
        if (firstobj) {
            productType = `${bookType.type}`;
            firstobj = false;
        } else {
            productType = `${productType}, ${bookType.type}`;
        }
    });
    return productType;
};

const getBookDetails = book => {
    let authorName, bookId, bookTitle, ebookISBN, audiobookISBN, productType;
    if (book) {
        const {
            id,
            meta: { title },
            authors,
            booktypes
        } = book;
        const authorNamesList = getAuthorNames(authors);
        bookId = id;
        bookTitle = title;
        authorName =
            authorNamesList.length > 1 ? authorNamesList : authorNamesList[0];
        ebookISBN = getBookIsbn(booktypes, "E_BOOK");
        audiobookISBN = getBookIsbn(booktypes, "AUDIO_BOOK");
        productType = getBookproductType(booktypes);
    }
    return { bookId, bookTitle, authorName, ebookISBN, audiobookISBN, productType };
};

const getMagazineDetails = magazine => {
    let authorName, bookId, bookTitle, ebookISBN, audiobookISBN, productType;
    if (typeof magazine.magazine !== 'undefined') {
        const {
            id,
            title,
            publishername,
            type
        } = magazine.magazine;
        bookId = id;
        bookTitle = title;
        authorName = publishername;
        productType = type;
    }
    return { bookId, bookTitle, authorName, ebookISBN, audiobookISBN, productType };
};

export const sendBookPageViewedToGTM = async (t, locale, bookInfo, authKeys, csrApiOrigin, host, referer) => {

    let urlConfig = UrlConfig({ hostname: csrApiOrigin });
    let bookDetails = {};

    if (typeof bookInfo !== 'undefined') {
        bookDetails = getBookDetails(bookInfo);
    }
    const event_id = getEventId();
    let currency = t("lang_common:currency");
    if (currency === "kr") currency = "SEK";
    if (currency === "€") currency = "EUR";
    const pageEvent = {
        event: 'BookPageViewed',
        currency,
        ...bookDetails,
        eventID: event_id,
        data: {
            ...bookDetails
        }
    };
    window && window.dataLayer && window.dataLayer.push(pageEvent);

    let fbData = {
        nx_event_id: event_id,
        nx_event_name: "ViewContent"
    };
    if (Cookies.get("_fbc")) fbData["fbc"] = Cookies.get("_fbc");
    if (Cookies.get("_fbp")) fbData["fbp"] = Cookies.get("_fbp");
    if (currency) fbData["nx_currency"] = currency;
    if (bookDetails.bookId) fbData["bookids"] = [bookDetails.bookId];

    let extraHeaders = {};
    if (typeof authKeys.authkey !== "undefined") {
        extraHeaders = {
            "nx-at": authKeys.authkey
        };
    }
    await SuperFetch.post({
        url: urlConfig.facebook,
        locale, referer, host,
        JSONdata: JSON.stringify(fbData),
        extraheaders: {
            "Content-Type": "application/json",
            ...extraHeaders
        }
    });
    return pageEvent;
};


export const sendMagazinePageViewedToGTM = async (t, locale, magazineInfo, authKeys, csrApiOrigin, host, referer) => {

    let urlConfig = UrlConfig({ hostname: csrApiOrigin });
    let bookDetails = {};

    if (typeof magazineInfo !== 'undefined') {
        bookDetails = getMagazineDetails(magazineInfo);
    }
    const event_id = getEventId();
    let currency = t("lang_common:currency");
    if (currency === "kr") currency = "SEK";
    if (currency === "€") currency = "EUR";
    const pageEvent = {
        event: 'BookPageViewed',
        currency,
        ...bookDetails,
        eventID: event_id,
        data: {
            ...bookDetails
        }
    };
    window && window.dataLayer && window.dataLayer.push(pageEvent);

    let fbData = {
        nx_event_id: event_id,
        nx_event_name: "ViewContent"
    };
    if (Cookies.get("_fbc")) fbData["fbc"] = Cookies.get("_fbc");
    if (Cookies.get("_fbp")) fbData["fbp"] = Cookies.get("_fbp");
    if (currency) fbData["nx_currency"] = currency;
    if (bookDetails.bookId) fbData["bookids"] = [bookDetails.bookId];

    let extraHeaders = {};
    if (typeof authKeys.authkey !== "undefined") {
        extraHeaders = {
            "nx-at": authKeys.authkey
        };
    }
    await SuperFetch.post({
        url: urlConfig.facebook,
        locale, referer, host,
        JSONdata: JSON.stringify(fbData),
        extraheaders: {
            "Content-Type": "application/json",
            ...extraHeaders
        }
    });
    return pageEvent;
};



export const subscriptionChosenClicked = async (t, locale, subscription, authKeys, signupBook, isFamilyPlaceholder, campaign, csrApiOrigin, host, referer) => {
    let urlConfig = UrlConfig({ hostname: csrApiOrigin });
    var campaignInfo = {
        code: campaign?.code || '',
        name: campaign?.name || '',
        price: campaign?.price || ''
    };
    var price = subscription?.price || '';
    var bookDetails = {};
    if (signupBook.book) {
        bookDetails = {
            bookId: signupBook.book.id,
            bookTitle: signupBook.book.title,
            authorName: signupBook.book.authorName,
            ebookISBN: signupBook.book.ebookISBN,
            audiobookISBN: signupBook.book.audiobookISBN
        };
    }
    const event_id = getEventId();
    let currency = t("lang_common:currency");
    if (currency === "kr") currency = "SEK";
    if (currency === "€") currency = "EUR";
    const pageEvent = {
        event: 'SubscriptionChosen',
        currency,
        Price: String(price),
        ...bookDetails,
        ...campaignInfo,
        subscription,
        eventID: event_id,
        data: {
            ...bookDetails,
            ...campaignInfo,
            subscription
        }
    };
    if (!isFamilyPlaceholder &&
        subscription) {
        window && window.dataLayer && window.dataLayer.push(pageEvent);
    }
    let fbData = {
        nx_event_id: event_id,
        nx_event_name: "Lead"
    };
    if (Cookies.get("_fbc")) fbData["fbc"] = Cookies.get("_fbc");
    if (Cookies.get("_fbp")) fbData["fbp"] = Cookies.get("_fbp");
    if (currency) fbData["nx_currency"] = currency;
    if (price) fbData["nx_value"] = price;
    if (bookDetails.bookId) fbData["bookids"] = [bookDetails.bookId];
    let extraHeaders = {};
    if (typeof authKeys.authkey !== "undefined") {
        extraHeaders = {
            "nx-at": authKeys.authkey
        };
    }
    if (!isFamilyPlaceholder) {
        await SuperFetch.post({
            url: urlConfig.facebook,
            locale, referer, host,
            JSONdata: JSON.stringify(fbData),
            extraheaders: {
                "Content-Type": "application/json",
                ...extraHeaders
            }
        });
    }
    return pageEvent;
};