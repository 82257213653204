import React from "react";
import Image from "next/image";
import styles from "../../styles/components/Logo.module.scss";

export default function Logo() {
  return (   
    <a href="/" alt="logo" className={styles.link}>
        <Image  priority={true} id="cp_lnk_nx_logo_h" className={styles.logo}  src="/cdn/images/logo-nextory-pink.svg" width={140} height={53} layout="fixed" alt={"logo"}/>    
    </a>
  );
}