/** @format */

import React, { useCallback, useContext } from 'react';
import { useRouter } from 'next/router';
import block from 'bem-css-modules';
import useTranslation from 'next-translate/useTranslation';
import { getTrialDay, getCtaUrl } from '../../helpers/common';
import { GTMCTAClicked } from '../../helpers/gtm';

import navigation from '../../styles/components/Navigation.module.scss';
import button from '../../styles/components/Button.module.scss';
import { t } from '../../helpers/common';
import { AppContext } from '../../helpers/AppContext';

import { menuListTopId, menuListMiddleId } from '../../helpers/listofid';

function Navigation({
  subscriptions,
  subscriptionInfo,
  userDetails,
  signupBook,
  authKeys,
}) {
  const ut = useTranslation();
  const { csrApiOrigin, host, referer } = useContext(AppContext);
  block.setSettings({
    modifierDelimiter: '--',
  });

  const nav = block(navigation);

  const day = getTrialDay(subscriptions);
  const { ctaUrl, trackCTAClickWithGTM, continuebutton } = getCtaUrl(
    ut.t,
    subscriptionInfo,
    userDetails,
    true
  );
  const isLoggedIn = userDetails.isLoggedIn;
  const { locale } = useRouter();
  const menuArray = t('lang_header:navigation', {}, { returnObjects: true });
  const index = [0, 1, 2, 3, 4, 5, 6];
  const indexForES = [0, 1, 2, 3, 4, 7, 5, 6];
  const navigationlinks =
    locale === 'es-ES'
      ? indexForES.map((i) => menuArray[i])
      : index.map((i) => menuArray[i]);

  const subLink = t('lang_header:subLink', {}, { returnObjects: true });

  const handleCTAClick = useCallback(async (e) => {
    e.preventDefault();
    if (trackCTAClickWithGTM) {
      await GTMCTAClicked(
        ut.t,
        locale,
        signupBook,
        authKeys,
        csrApiOrigin,
        host,
        referer
      );
    }
    window.location.href = ctaUrl;
  }, []);

  const handleSearchClick = () => {
    let searchFieldid = document.querySelector('#searchField_id');
    let hamburgerclosebutton = document.querySelector(
      '#hamburger_close_button'
    );
    if (searchFieldid) {
      searchFieldid.style.visibility = 'visible';
      if (hamburgerclosebutton) {
        hamburgerclosebutton.click();
      }
    }
  };

  return (
    <React.Fragment>
      <div className={nav()}>
        <div className={nav('navigationHolder', { newAlign: true })}>
          <ul className={nav('list')}>
            {navigationlinks.map((item, index) => {
              let hrefurl = String(item.url_link).includes('lang_route')
                ? t(item.url_link)
                : item.url_link
                ? item.url_link
                : item.link;
              hrefurl =
                t(item.url_link) === t('lang_route:generic.searchSuggestion')
                  ? '#'
                  : hrefurl;
              let isSearch =
                t(item.url_link) === t('lang_route:generic.searchSuggestion');
              if (item.text === 'NULL') {
                return <></>;
              }
              return (
                <li className={nav('item', { mainLink: true })} key={item.text}>
                  {/* {isSearch && <a className={nav('link')} onClick={handleSearchClick} id={menuListTopId[index]} > {item.text} </a> } */}
                  {!isSearch && (
                    <a
                      className={nav('link')}
                      href={hrefurl}
                      id={menuListTopId[index]}
                    >
                      {' '}
                      {item.text}{' '}
                    </a>
                  )}
                </li>
              );
            })}
          </ul>

          <ul className={nav('list', { subLink: true })}>
            {subLink.map((item, index) => {
              let hrefurl = String(item.url_link).includes('lang_route')
                ? t(item.url_link)
                : item.url_link
                ? item.url_link
                : item.link;
              hrefurl =
                t(item.url_link) === t('lang_route:generic.searchSuggestion')
                  ? '#'
                  : hrefurl;
              let id = String(item.text).replace(/\s/g, '');
              id =
                t(item.url_link) === t('lang_route:generic.searchSuggestion')
                  ? 'searchitem'
                  : menuListMiddleId[index];
              return (
                <li className={nav('item')} key={item.text}>
                  <a className={nav('subLink')} href={hrefurl} id={id}>
                    {item.text}
                  </a>
                </li>
              );
            })}
          </ul>
          {!isLoggedIn && (
            <ul className={nav('list', { buttonGroup: true })}>
              <li className={nav('item', { groupItem: true })}>
                <a
                  className={`${button.btn} ${button['btn--primary']} ${button['btn--navbuttons']} ${button['btn--noTransform']}`}
                  onClick={handleCTAClick}
                  href={'#'}
                  id={continuebutton ? ' ' : 'hp_mn_lnk_reg'}
                >
                  {continuebutton
                    ? t('lang_homepage:hero.buttons.paymentRequired.0.text')
                    : t('lang_default:components.1.buttons.default', {
                        day: day,
                      })}
                </a>
              </li>

              {!continuebutton && <li className={nav('item', { groupItem: true })}>
                <a
                  className={`${button.btn} ${button['btn--secondaryLight']} ${button['btn--navbuttons']}`}
                  href={t('lang_route:mypages.login')}
                  id='hp_mn_lnk_login'
                >
                  {t('lang_header:menu.button.login')}
                </a>
              </li>}
            </ul>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

export default Navigation;
